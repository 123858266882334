export type TiRoute = {
  name: string
  path: string
}

interface IUseNavigation {
  routes: TiRoute[]
}

export const useNavigation = (params: IUseNavigation) => {
  const route = useRoute()
  const localePath = useLocalePath()

  const currentPage = ref<TiRoute['path']>()

  const availableRoutes = ref<TiRoute[]>(params.routes)

  const routerClick = (value: TiRoute) => {
    currentPage.value = value.path
    redirect(value.path)
  }

  const redirect = (path: TiRoute['path']) => {
    navigateTo(localePath(path))
  }

  watchEffect(() => {
    const currentRoutePath = route.path

    currentPage.value = availableRoutes.value.find(
      (el) => currentRoutePath === el.path
    )?.path
  })

  return {
    currentPage,
    availableRoutes,
    routerClick,
  }
}
