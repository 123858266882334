<template>
  <v-dialog
    v-model="isOpen"
    :persistent="isPersistent"
    width="500"
    @update:model-value="updateDialog"
  >
    <v-card title="Выберите свой город">
      <div class="px-[32px] pb-[20px] space-y-6">
        <FormInput v-model="searchText" />
        <div class="grid grid-cols-[1fr,1fr] gap-x-[128px] gap-y-[32px]">
          <div
            v-for="chunk in cityChunks"
            :key="chunk[0].name"
            class="inline-flex flex-col items-start gap-[24px]"
          >
            <div
              v-for="city in chunk"
              :key="city.name"
              class="cursor-pointer text-[14px] hover:text-main font-semibold !font-inter"
              @click="selectCountry(city)"
            >
              {{ $t(city.name) }}
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { useIndexStore } from '~/stores'

const emits = defineEmits(['close'])
defineProps({
  isPersistent: {
    type: Boolean,
    default: true,
  },
})

const route = useRoute()
const store = useIndexStore()
const { locale } = useI18n()

const isOpen = ref(true)

const searchText = ref('')

const countries = computed(() => store.countries_list)

const filteredCities = computed(() => {
  if (!searchText.value.trim()) return countries.value
  return countries.value.filter((city) =>
    city.name.toLowerCase().includes(searchText.value.toLowerCase())
  )
})
const cityChunks = computed(() => {
  return chunkArray(filteredCities.value, 3)
})

function selectCountry(value) {
  store.setCountry(value)
  const routeSegments = route.fullPath.split('/')
  const languageSegment = routeSegments[1]
  const slugSegmentIndex = 2

  if (languageSegment === locale.value) {
    routeSegments[slugSegmentIndex] = value.slug
  } else {
    routeSegments.splice(1, 0, locale.value)
    routeSegments[slugSegmentIndex] = value.slug
  }

  const newRoute = routeSegments.join('/')
  const currentUrl = new URL(window.location.href)
  currentUrl.pathname = `${newRoute}`

  window.location.href = currentUrl.href
}

function updateDialog(newValue) {
  if (!newValue) {
    emits('close')
    isOpen.value = false
  }
}

function chunkArray(array, chunkSize) {
  return Array(Math.ceil(array.length / chunkSize))
    .fill()
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize))
}

useAsyncData('countries', () => {
  store.fetchCountries()
})
</script>
<style>
.v-card-title {
  font-size: 1.5rem;
}
.v-dialog > .v-overlay__content > .v-card > .v-card-item,
.v-dialog > .v-overlay__content > form > .v-card > .v-card-item {
  padding: 32px 32px 24px 32px;
}
.v-dialog > .v-overlay__content > .v-card,
.v-dialog > .v-overlay__content > form > .v-card {
  border-radius: 8px;
}
</style>
<style scoped lang="scss">
.country {
  &-list {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  &-name {
    font-weight: 600;
    cursor: pointer;
    margin: 0 20px 0 2px;
  }
}
</style>
