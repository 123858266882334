<template>
  <div fluid>
    <div class="flex justify-between items-center flex-nowrap">
      <div class="flex gap-x-16 items-center">
        <v-img
          class="header__logo !w-[103px] !h-[20px] md:!w-[144px] md:!h-[28px] lg:!w-[160px] lg:!h-[32px]"
          src="/tt-logo.svg"
          alt="tt-logo"
          @click="routerClick"
        />

      </div>
      <div class="hidden lg:block">
        <TTHeaderNavBar />
      </div>
      <div>
        <client-only>
          <div class="header__settings gap-[22px]">
            <v-img
              v-if="mdAndUp"
              :max-height="32"
              :max-width="32"
              :width="32"
              :height="32"
              src="/img/halal.png"
              alt="halal logo"
            />
            <TTHeaderLanguageSelector v-if="mdAndUp" />
            <SharedCountrySelectNavbar class="hidden lg:block" />
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              location="bottom"
            >
              <template #activator="{ props }">
                <div v-if="smAndUp" variant="text" v-bind="props">
                  <div v-html="userIcon" />
                </div>
              </template>
              <v-card
                elevation="2"
                rounded-lg
                class="modal"
                :max-width="user ? '100%' : 300"
              >
                <div v-if="!user">
                  <v-tabs
                    v-model="tab"
                    align-tabs="center"
                    color="teal-lighten-1"
                  >
                    <v-tab
                      :value="1"
                      class="!font-inter"
                      style="text-transform: capitalize"
                    >
                      {{ $t('navbar_auth_enter') }}
                    </v-tab>
                    <v-tab
                      :value="2"
                      class="!font-inter"
                      style="text-transform: capitalize"
                    >
                      {{ $t('navbar_auth_register') }}
                    </v-tab>
                  </v-tabs>
                  <v-list v-if="tab === 1" class="!p-6">
                    <div v-if="!loginOtp">
                      <FormGroup
                        is-required
                        class="!font-inter"
                        :label="$t('navbar_login_index_loginPhoneInput')"
                      >
                        <FormInput
                          v-model="form.login"
                          type="tel"
                          inputmode="tel"
                          maska="+7 (###) ###-##-##"
                          placeholder="+7 (___) ___ __ __"
                        />
                      </FormGroup>
                    </div>
                    <div v-else>
                      <div class="modal-label !font-inter">
                        {{ $t('navbar_CodeValidator_userPhone_01') }}
                        {{ form.login }}
                        {{ $t('navbar_CodeValidator_userPhone_02') }}
                        {{ $t('navbar_CodeValidator_userPhone_03') }}
                      </div>
                      <FormOTP v-model="code" :error="invalidOtp" />
                      <div
                        v-if="showInterval"
                        class="hover:text-main"
                        style="font-size: 13px"
                      >
                        {{ $t('repeat_send') }} {{ interval }}
                      </div>
                      <div
                        v-if="!intervalDisabled"
                        style="font-size: 13px; cursor: pointer"
                        @click="sendOtpAgain"
                      >
                        {{ $t('navbar_ActivationCode_resendCode') }}
                      </div>
                    </div>
                  </v-list>
                  <v-list v-if="tab === 2" class="!p-6">
                    <div v-if="!registerOtp" class="space-y-1">
                      <FormGroup
                        is-required
                        class="!font-inter"
                        :label="$t('navbar_signup_register_name')"
                      >
                        <FormInput
                          v-model="form.name"
                          placeholder="Имя"
                          type="text"
                          :label="$t('request_order_modal_input_name')"
                        />
                      </FormGroup>
                      <FormGroup
                        is-required
                        class="!font-inter"
                        :label="$t('navbar_login_index_loginPhoneInput')"
                      >
                        <FormInput
                          v-model="form.login"
                          type="tel"
                          inputmode="tel"
                          maska="+7 (###) ###-##-##"
                          placeholder="+7 (___) ___ __ __"
                        />
                      </FormGroup>
                    </div>
                    <div v-else>
                      <div class="modal-label !font-inter">
                        {{ $t('navbar_CodeValidator_userPhone_01') }}
                        {{ form.login }}
                        {{ $t('navbar_CodeValidator_userPhone_02') }}
                        {{ $t('navbar_CodeValidator_userPhone_03') }}
                      </div>
                      <FormOTP v-model="code" :error="invalidOtp" />
                      <div v-if="showInterval" style="font-size: 13px">
                        {{ $t('repeat_send') }} {{ interval }}
                      </div>
                      <div
                        v-if="!intervalDisabled"
                        style="font-size: 13px; cursor: pointer"
                        @click="sendOtpAgain"
                      >
                        {{ $t('navbar_ActivationCode_resendCode') }}
                      </div>
                    </div>
                  </v-list>
                  <v-card-actions class="!px-6 !pb-6">
                    <v-spacer></v-spacer>
                    <BaseButton
                      variant="main"
                      :disabled="!disableHandler"
                      :text="$t(actionButton)"
                      @click="action"
                    />
                  </v-card-actions>
                </div>
                <div v-else class="p-2">
                  <NuxtLinkLocale
                    :to="`/${indexStore.current_county?.slug}/cabinet`"
                    class="list-link d-flex cursor-pointer mt-2"
                  >
                    <div v-html="userIcon" />
                    Профиль
                  </NuxtLinkLocale>
                  <div
                    class="list-link d-flex cursor-pointer mt-2"
                    @click="exitHandler"
                  >
                    <div v-html="exitIcon"></div>
                    Выход
                  </div>
                </div>
              </v-card>
            </v-menu>
            <TTHeaderBasket />
            <!-- <TTModalCart /> -->
            <TTHeaderBurgerMenu />
          </div>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'

import { exitIcon, userIcon } from '@/config/icon.config'
import { useIndexStore } from '@/stores'
import { useAuthStore } from '@/stores/auth'
import { useCartStore } from '~/stores/cart'

const indexStore = useIndexStore()
const authStore = useAuthStore()
const cartStore = useCartStore()
const localePath = useLocalePath()
const { mdAndUp, smAndUp } = useDisplay()

const menu = ref(false)
const tab = ref(1)
const loginOtp = ref(false)
const registerOtp = ref(false)
const invalidOtp = ref(false)
const code = ref<string | null>(null)
const form = reactive({ login: '', password: '', name: '' })
const showInterval = ref(false)
const interval = ref(60)
const intervalDisabled = ref(true)
let intervalTimer: NodeJS.Timeout | undefined = undefined // eslint-disable-line

const actionButton = computed(() => {
  if (tab.value === 1 && !loginOtp.value) return 'navbar_login_index_get_code'
  if (tab.value === 1 && loginOtp.value) return 'navbar_auth_enter'
  if (tab.value === 2) return 'navbar_auth_register'
  return ''
})

const user = computed(() => authStore.user)

const exitHandler = async () => {
  await navigateTo(localePath(`/${indexStore.current_county?.slug}`))
  authStore.logout()
  window.location.reload()
}

const startOtpTimer = () => {
  interval.value = 60
  intervalDisabled.value = true
  showInterval.value = true
  intervalTimer = setInterval(() => {
    if (interval.value > 0) {
      interval.value -= 1
    } else {
      intervalDisabled.value = false
      showInterval.value = false
      clearInterval(intervalTimer)
    }
  }, 1000)
}

const action = () => {
  if (tab.value === 1 && !loginOtp.value) {
    authStore
      .getUserCode({
        phone: form.login.replace(/\D+/g, ''),
        is_registration: false,
        city_id: indexStore.current_county?.id,
      })
      .then(() => {
        loginOtp.value = true
        startOtpTimer()
      })
  }
  if (tab.value === 1 && loginOtp.value) {
    authStore
      .checkOtp({
        phone: form.login.replace(/\D+/g, ''),
        code: code.value,
        city_id: indexStore.current_county?.id,
      })
      .then((res) => {
        if (res.access_token) menu.value = false
        cartStore.fetchProductsToBasket()
      })
      .catch((err) => {
        if (err._data.errors.code) {
          invalidOtp.value = true
        }
      })
  }
  if (tab.value === 2 && !registerOtp.value) {
    authStore.signUp({
      phone: form.login.replace(/\D+/g, ''),
      name: form.name,
      is_registration: true,
      city_id: indexStore.current_county?.id,
    })
    registerOtp.value = true
    startOtpTimer()
  }
  if (tab.value === 2 && registerOtp.value) {
    authStore
      .checkOtp({
        phone: form.login.replace(/\D+/g, ''),
        code: code.value,
        city_id: indexStore.current_county?.id,
      })
      .then((res) => {
        if (res.access_token) {
          menu.value = false
        }
      })
      .catch((err) => {
        if (err._data.errors.code) {
          invalidOtp.value = true
        }
      })
  }
}

watch(code, () => {
  if (code.value) {
    invalidOtp.value = false
  }
})

const sendOtpAgain = () => {
  authStore.getUserCode({
    phone: form.login.replace(/\D+/g, ''),
    is_registration: false,
    city_id: indexStore.current_county?.id,
  })
  startOtpTimer()
}

const routerClick = () =>
  navigateTo(localePath(`/${indexStore.current_county?.slug}`))

const disableHandler = computed(() => {
  if (tab.value === 1 && !loginOtp.value) {
    return form.login.replace(/\D+/g, '').length === 11
  } else if (tab.value === 1 && loginOtp.value) {
    return code.value && code.value.length === 6
  }
  if (tab.value === 2 && !registerOtp.value) {
    return form.login.replace(/\D+/g, '').length === 11 && form.name
  } else if (tab.value === 2 && registerOtp.value) {
    return code.value && code.value.length === 6
  }
  return false
})
</script>

<style scoped lang="scss">
header {
  position: sticky;
}
.header {
  &__wrapper {
    position: sticky;
  }

  &__logo {
    cursor: pointer;
  }

  &__settings {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.modal {
  &-label {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &-tab {
    width: 300px;
    margin: auto;
  }
  &-submit {
    color: white;
    background: rgb(var(--v-theme-main));
    padding: 10px;
    width: 100%;
    text-transform: capitalize;
    border-radius: 8px;
  }
}
.v-tabs--fixed-tabs .v-slide-group__content > *:first-child,
.v-tabs--align-tabs-center .v-slide-group__content > *:first-child {
  margin-inline-start: initial;
}
</style>
<style>
.v-otp-input__field {
  border-radius: 8px !important;
}
</style>
