<template>
  <div class="basket-product relative">
    <div class="flex">
      <v-img
        class="basket-product-img"
        cover
        lazy-src="/img/default-lazy-load.jpg"
        :src="product?.image"
      />

      <SharedNextDayIcon
        v-if="product.next_day"
        class="!top-[5px] !-left-[10px]"
      />
      <SharedAvailableForPickupIcon
        v-if="product.is_special"
        class="!top-[5px] !-left-[10px]"
      />
      <div class="basket-product-part_one">
        <h1>{{ product.name }}</h1>

        <div class="basket-product-price__counter">
          <button
            class="basket-product-price__counter__btn"
            @click="minusProduct(product.id)"
          >
            -
          </button>
          <span class="basket-product-price__counter__count">
            {{ product.quantity }}
          </span>
          <button
            class="basket-product-price__counter__btn"
            @click="addProduct(product)"
          >
            +
          </button>
        </div>
      </div>
    </div>

    <div class="basket-product-part_two">
      <div
        class="basket-product-part_two__close"
        @click="removeProduct(product.hash)"
      >
        <v-icon>mdi-close</v-icon>
      </div>
      <div class="basket-product-part_two__price">
        {{ product.price.toLocaleString() }} &nbsp₸
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCartStore } from '~/stores/cart'

defineProps<{
  product: Product
}>()

const cartStore = useCartStore()

const minusProduct = (id: number) => cartStore.minusProduct(id)
const addProduct = (data: number) => cartStore.setProductToBasket(data)
const removeProduct = (hash: string) => cartStore.removeProduct(hash)
</script>
<style scoped lang="scss">
.basket-product {
  font-family: 'Inter', serif;
  display: flex;
  justify-content: space-between;
  &-img {
    width: 88px;
    height: 88px;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 8px;
  }
  &-part_one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 22px;
    padding-left: 20px;
  }
  &-part_two {
    position: relative;
    margin-right: 8px;
    &__close {
      position: absolute;
      top: 15px;
      right: 0;
      cursor: pointer;
    }
    &__price {
      margin-top: 60px;
      font-weight: 600;
    }
  }
  &-price {
    &__counter {
      margin-top: 8px;
      border: 1px solid #989898;
      display: flex;
      align-items: center;
      max-width: 80px;
      padding: 5px 10px 5px 10px;
      border-radius: 8px;
      margin-left: 5px;

      &__btn {
        height: 1.3rem;
        color: #989898;
        font-size: 13px;
        font-weight: 600;
        width: 2rem;
      }

      &__count {
        padding: 0 1rem;
        font-weight: 600;
        margin-bottom: -2px;
      }
    }
  }
}
</style>
