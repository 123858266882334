<template>
  <v-btn
    class="!block lg:!hidden"
    variant="text"
    icon="mdi-menu"
    @click="drawerHandler"
  />
  <teleport to="body">
    <v-navigation-drawer
      v-if="mobile"
      v-model="drawer"
      :width="width"
      location="left"
      style="z-index: 1999"
    >
      <v-container>
        <v-list>
          <v-list-item v-for="link in availableRoutes" :key="link.path">
            <NuxtLinkLocale
              :to="`/${indexStore.current_county?.slug}/${link.path}`"
              @click="drawer = false"
            >
              <v-btn
                class="burger__link"
                variant="text"
                :color="link.path === currentPage ? 'main' : 'theme-dark-gray'"
                >{{ $t(link.name) }}
              </v-btn>
            </NuxtLinkLocale>
          </v-list-item>
          <v-list-item>
            <SharedCountrySelectNavbar
              class="burger__link"
              @click="drawer = false"
            />
          </v-list-item>
          <v-list-item>
            <TTHeaderLanguageSelector class="burger__link" />
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
  </teleport>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'

import { useIndexStore } from '~/stores'
import { useGlobalScrollStore } from '~/stores/scrollController'

const scrollController = useGlobalScrollStore()

const { width, mobile } = useDisplay()
const drawer = ref(false)
const indexStore = useIndexStore()
const drawerHandler = () => {
  drawer.value = !drawer.value
  scrollController.setScroll(!scrollController.scroll)
}

const { currentPage, availableRoutes } = useNavigation({
  routes: [
    {
      path: '/',
      name: 'navbar_menu',
    },
    {
      path: 'bonus',
      name: 'navbar_bonus',
    },
    {
      path: 'about',
      name: 'navbar_aboutus',
    },
    {
      path: 'contacts',
      name: 'navbar_contacts',
    },
  ],
})
</script>

<style scoped lang="scss">
.mobil-nav {
  position: fixed;
  top: 0;
  margin-top: 80px;
  width: 100%;
  height: 100vh;
  background-color: red;
}

.burger__link {
  color: rgb(var(--v-theme-theme-dark-gray));
  text-transform: capitalize;
  font-weight: 600;
  font-size: 32px;
}
</style>
<style>
.v-navigation-drawer--temporary.v-navigation-drawer--active {
  box-shadow: none !important;
}
</style>
