<template>
  <div class="tt-recommendation-product">
    <div class="tt-recommendation-product__image">
      <SharedImageLazy
        :img-src="product.image"
        :alt-text="product.name"
        @click="handleClick"
      />
    </div>
    <div class="tt-recommendation-product__info">
      <span class="tt-recommendation-product__name">
        {{ product.name }}
      </span>
      <span class="tt-recommendation-product__price">
        {{ numberWithSpaces(product.price) }} ₸
      </span>
    </div>
    <BaseButton
      variant="main-outline"
      :text="$t('home_list_btn_add2cart')"
      @click="addToCart"
    />
  </div>
</template>

<script setup lang="ts">
import { useIndexStore } from '~/stores'
import { useCartStore } from '~/stores/cart'
import { useProductStore } from '~/stores/product'

const props = defineProps<{
  product: Product
}>()
const emits = defineEmits<{
  (e: 'click'): void
}>()
const cartStore = useCartStore()
const indexStore = useIndexStore()
const productStore = useProductStore()
const { notify } = useToast()

const { t } = useI18n()
const localePath = useLocalePath()

const handleClick = () => {
  navigateTo(
    localePath(
      `/${indexStore.current_county?.slug}/product/${props.product.id}`
    )
  )
  emits('click')
}
const assertProductIsAdditionalProduct = (product) =>
  product.category_id.toString() ===
  productStore.noTakeawayCategoryId?.toString()

const checkCartForNonAdditionalProduct = () =>
  cartStore.products.some(
    (p) =>
      p.category_id.toString() !== productStore.noTakeawayCategoryId?.toString()
  )

function addToCart() {
  if (!canAddProductToCart(props.product)) {
    return
  }
  cartStore.setProductToBasket(props.product).then(() => {
    notify({
      type: 'success',
      text: `Ваш продукт ${props.product.name} успешно добавлен в корзину`,
    })
  })
}

function canAddProductToCart(product) {
  if (!productStore.noTakeawayCategoryId) {
    return true
  }
  if (
    assertProductIsAdditionalProduct(product) &&
    !checkCartForNonAdditionalProduct()
  ) {
    notify({
      type: 'error',
      text: t('no_add_without_cake'),
    })

    return false
  }
  return true
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/typography"

.tt-recommendation-product
  font-size: $fs-small
  line-height: 1rem
  max-width: 148px
  &__info
    margin-top: 12px
    display: flex
    justify-content: space-between
    height: 34px
  &__price
    font-weight: $fw-semibold
    white-space: nowrap
  &__image
    width: 148px
    height: 112px
  .tt-button
    margin-top: 12px
    height: 38px
    font-size: 14px
    padding: 0
</style>
