export const useGlobalScrollStore = defineStore({
  id: 'useScroll',
  state: () => ({
    scroll: true,
  }),
  actions: {
    setScroll(flag: boolean) {
      this.scroll = flag
    },
  },
})
