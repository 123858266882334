<template>
  <div>
    <v-btn
      variant="text"
      prepend-icon="mdi-map-marker-outline"
      class="!capitalize px-0"
      @click="isOpen = true"
    >
      <span class="text-main text-[1rem] font-semibold tracking-normal">
        {{ currentCountry?.name || 'Выберите ваш город' }}
      </span>
    </v-btn>
    <v-dialog v-model="isOpen" width="500">
      <v-card class="relative">
        <v-card-title class="px-[32px] font-[600] pt-8 pb-4">
          Выберите свой город
        </v-card-title>
        <i
          class="icon-x-mark text-[32px] text-black/50 absolute top-[20px] right-[20px] cursor-pointer"
          @click="isOpen = false"
        />
        <div class="px-[32px] pb-[20px] space-y-6">
          <FormInput v-model="searchText" />
          <div class="grid grid-cols-[1fr,1fr] gap-x-[128px] gap-y-[32px]">
            <div
              v-for="chunk in cityChunks"
              :key="chunk[0].name"
              class="inline-flex flex-col items-start gap-[24px]"
            >
              <div
                v-for="city in chunk"
                :key="city.name"
                class="cursor-pointer text-[14px] hover:text-main font-semibold !font-inter text-[#000000]"
                @click="selectCountry(city)"
              >
                {{ $t(city.name) }}
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup lang="ts">
import { useIndexStore } from '~/stores'

const route = useRoute()
const { locale } = useI18n()
const store = useIndexStore()

const searchText = ref('')
const isOpen = ref(false)
const currentCountry = computed(() => store.country)
const countries = computed(() => store.countries_list)

const filteredCities = computed(() => {
  if (!searchText.value.trim()) return countries.value
  return countries.value.filter((city) =>
    city.name.toLowerCase().includes(searchText.value.toLowerCase())
  )
})
const cityChunks = computed(() => {
  return chunkArray(filteredCities.value, 3)
})

function selectCountry(value) {
  store.setCountry(value)
  const routeSegments = route.fullPath.split('/')
  const languageSegment = routeSegments[1]
  const slugSegmentIndex = 2

  if (languageSegment === locale.value) {
    routeSegments[slugSegmentIndex] = value.slug
  } else {
    routeSegments.splice(1, 0, locale.value)
    routeSegments[slugSegmentIndex] = value.slug
  }

  const newRoute = routeSegments.join('/')
  const currentUrl = new URL(window.location.href)

  currentUrl.pathname = `${newRoute}`

  window.location.href = currentUrl.href
}

function chunkArray(array, chunkSize) {
  return Array(Math.ceil(array.length / chunkSize))
    .fill()
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize))
}
</script>
<style>
.v-dialog > .v-overlay__content > .v-card > .v-card-item,
.v-dialog > .v-overlay__content > form > .v-card > .v-card-item {
  padding: 32px 32px 24px 32px;
}
.v-dialog > .v-overlay__content > .v-card,
.v-dialog > .v-overlay__content > form > .v-card {
  border-radius: 8px;
}
</style>

<style scoped lang="scss">
.country {
  &-list {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  &-name {
    font-weight: 600;
    cursor: pointer;
    margin: 0 20px 0 2px;
  }
}
</style>
