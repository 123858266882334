<template>
  <v-footer class="footer" color="theme-light-blue">
    <div fluid class="py-8 container">
      <div
        class="flex mt-[26px] lg:mt-[40px] md:mt-[32px] !justify-between flex-col md:flex-row md:justify-between gap-y-6 gap-x-[80px]"
      >
        <v-img
          :width="160"
          :height="32"
          :max-width="160"
          :max-height="32"
          src="/tt-logo.svg"
          alt="tt-logo"
          @click="routerClick"
        />

        <div class="flex flex-wrap gap-[32px]">
          <NuxtLinkLocale
            v-for="(item, index) in routes"
            :key="index"
            class="link lg:text-center cols-span-2 text-[#1d1d1d]"
            :to="item?.path"
            @click="changeRoute"
          >
            {{ $t(item.name) }}
          </NuxtLinkLocale>
        </div>

        <div class="contact !flex !items-start !flex-col">
          <a href="tel:+77171155660" class="hover:text-main">
            +7 (771) 555 66 00
          </a>

          <div class="links flex">
            <a
              href="https://api.whatsapp.com/send/?phone=77715556060&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D1%81%D0%B4%D0%B5%D0%BB%D0%B0%D1%82%D1%8C+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C"
              target="_blank"
              ><v-icon>mdi-whatsapp</v-icon></a
            >
            <a href="https://www.instagram.com/taptatti/" target="_blank"
              ><v-icon>mdi-instagram</v-icon></a
            >
            <a href="https://www.facebook.com/taptattiastana/" target="_blank">
              <v-icon>mdi-facebook</v-icon></a
            >
            <a
              href="https://www.youtube.com/channel/UCwovcdNkxuRBxnYAiOkbIAw?view_as=subscriber"
              target="_blank"
            >
              <v-icon>mdi-youtube</v-icon></a
            >
          </div>
        </div>
      </div>
      <div class="flex justify-between flex-wrap">
        <div class="tt-footer__law">
          <div class="tt-footer__rights flex-col lg:flex-row">
            <NuxtLinkLocale
              class="tt-footer__user-agreement lg:!border-r hover:underline"
              :to="`/${indexStore.current_county?.slug}/user-agreement`"
            >
              {{ $t('footer_user-agreement') }}
            </NuxtLinkLocale>
            <NuxtLinkLocale
              class="tt-footer__user-agreement hover:underline pl-0 lg:!pl-4"
              :to="`/${indexStore.current_county?.slug}/policy`"
            >
              {{ $t('Policy_views_breadcrumbs_index') }}
            </NuxtLinkLocale>
          </div>
        </div>
        <div class="tt-footer__law">
          <div class="tt-footer__rights">
            <p class="tt-footer__copyright !text-[14px] !font-inter">
              {{ $t('Footer_copyright', { year: new Date().getFullYear() }) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-footer>
</template>

<script setup lang="ts">
import { useIndexStore } from '~/stores'

const indexStore = useIndexStore()
const localePath = useLocalePath()

const routerClick = () =>
  navigateTo(localePath(`/${indexStore.current_county?.slug}`))
const routes = [
  {
    path: `/${indexStore.current_county?.slug}`,
    name: 'navbar_menu',
  },
  {
    path: `/${indexStore.current_county?.slug}/bonus`,
    name: 'navbar_bonus',
  },
  {
    path: `/${indexStore.current_county?.slug}/about`,
    name: 'navbar_aboutus',
  },
  {
    path: `/${indexStore.current_county?.slug}/contacts`,
    name: 'navbar_contacts',
  },
  {
    path: `/${indexStore.current_county?.slug}/carrier`,
    name: 'Footer_careers',
  },
  {
    path: `/${indexStore.current_county?.slug}/gift`,
    name: 'navbar_gift_certificate',
  },
  {
    path: `/${indexStore.current_county?.slug}/franchising`,
    name: 'navbar_franchise',
  },
  {
    path: `/${indexStore.current_county?.slug}/rent`,
    name: 'navbar_rent',
  },
]
function changeRoute() {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 300)
}
</script>
<style lang="sass">
@import url('@/assets/styles/footer.sass')
</style>
<style scoped lang="scss">
.footer {
  // padding: 0 150px;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
}

.links {
  display: flex;
  gap: 16px;
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-weight: 600;
}

.link {
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: rgb(var(--v-theme-main));
  }
}
</style>
