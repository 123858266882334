<template>
  <div class="tt-modal-container">
    <div class="tt-modal-container__modal">
      <div class="tt-modal-container__header">
        <h2 class="tt-modal-container__title ff-mont">
          {{ $t('home_list_btn_request_product') }}
        </h2>
        <div class="tt-icon-button" @click="close">
          <img src="@/assets/icons-new/close_xl.svg" />
        </div>
      </div>
      <div class="tt-request-modal">
        <form v-if="!formSent" class="tt-request-modal__form">
          <div class="tt-input-field">
            <FormGroup :label="$t('request_order_modal_input_name')">
              <FormInput
                v-model="values.name"
                :error="$v.name.$error"
                :placeholder="$t('request_order_modal_fio_placeholder')"
              />
            </FormGroup>
          </div>

          <div class="tt-input-field">
            <FormGroup :label="$t('request_order_modal_input_phone')">
              <FormInput
                v-model="values.phone"
                v-maska="'+7 (###) ###-##-##'"
                :error="$v.phone?.$error"
                placeholder="+7 (___) ___ __ __"
                type="tel"
              />
            </FormGroup>
          </div>

          <p
            v-if="city.delivery_commission === 0"
            class="tt-request-modal__form-text-delivery"
          >
            {{ $t('delivery_regions_for_yandex') }}
          </p>

          <p v-else class="tt-request-modal__form-text-delivery">
            {{ $t('delivery_regions_for_comission') }}
            <span>{{ city.delivery_commission }}</span>
          </p>

          <p v-if="!isWithinTimeRange" class="tt-request-modal__form-text-work">
            {{ $t('validation_for_work_time_01') }}
            <span> {{ city?.name }}</span>
            {{ $t('validation_for_work_time_02') }}
            <br />
            {{ $t('validation_for_work_time_03') }}
            <span>{{ city?.start_work?.slice(0, 5) }}</span>
            {{ $t('validation_for_work_time_04') }}
            <span>{{ city?.end_work?.slice(0, 5) }}</span>
          </p>

          <BaseButton
            variant="main"
            :text="$t('request_order_modal_btn2send')"
            class="w-full py-2 mt-2"
            :disabled="isWithinTimeRange"
            @click.prevent="submitRequest"
          />
        </form>

        <div v-else class="tt-request-modal__success">
          <p class="justify-content-center align-items-center">
            {{ $t('request_order_modal_save_success_send_to_messenger') }}
          </p>
          <div class="float-right">
            <a
              :href="
                'https://wa.me/' +
                city.whatsapp_phone +
                '/' +
                `?text=${frontURL}/product/${values.product_id}%0A` +
                `product: ${modalProduct.name}%0A` +
                `city: ${city.name}%0A` +
                `name: ${form.name}%0A` +
                `mobile: ${form.phone}`
              "
              target="_blank"
              class="!bg-main rounded-lg text-white p-2 mt-4"
            >
              {{
                $t('request_order_modal_save_success_send_to_messenger_button')
              }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="tt-modal-container__backdrop" @click="close"></div>
  </div>
</template>

<script setup lang="ts">
import { useRequestModalStore } from '~/stores/requestModal'

const requestModalStore = useRequestModalStore()
const { notify } = useToast()

const city = ref({})
const form = reactive({
  name: '',
  phone: '',
  city_id: null,
  product_id: null,
})
const { required } = useTranslate()
const { $v, values } = useForm(form, {
  phone: {
    required,
    phoneValidator,
  },
  name: {
    required,
  },
  city_id: {
    required,
  },
  product_id: {
    required,
  },
})

const loading = ref(false)
const attemptedSubmit = ref(false)
const formSent = ref(false)
const frontURL = process.env.VUE_APP_BASE_URL || 'http://localhost:8080/'

const showRequestOrderModal = computed(
  () => requestModalStore.showRequestOrderModal
)
const modalProduct = computed(() => requestModalStore.modalProduct)
const isWithinTimeRange = computed(() => {
  const currentTime = new Date()
  const startWork = parseTime(city.value?.start_work)
  const endWork = parseTime(city.value?.end_work)

  return currentTime >= startWork && currentTime <= endWork
})
function parseTime(timeString) {
  try {
    const date = new Date()
    const [hours, minutes, seconds] = timeString?.split(':').map(Number)

    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(seconds)

    return date
  } catch (e) {
    return null
  }
}

async function submitRequest() {
  attemptedSubmit.value = true
  console.log('efw')
  if (modalProduct.value) {
    form.product_id = modalProduct.value.id
  }
  if (city.value) {
    form.city_id = city.id
  }

  // if ($v.value.$invalid) {
  //   $v.value.$touch()
  //   return
  // }
  loading.value = true

  const formData = new FormData()
  Object.keys(values).forEach((item) => {
    formData.append(item, values[item])
  })

  try {
    console.log('formData', formData)
    await useApi().$post('/request-order', formData)
    console.log('sent')
    formSent.value = true
    notify({
      type: 'success',
      text: 'Your message was sent successfully!',
    })
  } catch (e) {
    console.log('error', e)
    notify({
      type: 'error',
      text: e,
    })
  } finally {
    loading.value = false
  }
}
function close() {
  requestModalStore.setShowRequestOrderModal(false)
}
onBeforeMount(() => {
  city.value = useCityCookie().get()
})
</script>

<style lang="sass" scoped>
@import "@/assets/styles/colors"
@import "@/assets/styles/typography"
@import "@/assets/styles/vars"

.tt-modal-container
  position: fixed
  z-index: 3000
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  &__modal
    position: relative
    z-index: $zi-modal
    background-color: $white
    border-radius: 8px
    overflow: hidden
    padding: 32px
  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 24px
  &__title
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    margin: 0
    color: var(--Black, #1D1D1D)
    text-align: center
    font-style: normal
    line-height: normal
  .tt-icon-button
    position: absolute
    top: 20px
    right: 20px
  &__backdrop
    position: absolute
    z-index: $zi-modal-backdrop
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.4)

.tt-request-modal
  display: flex
  justify-content: space-between
  gap: 32px
  &__form
    width: 100%
    max-width: 544px
    .tt-input-field
      margin-bottom: 10px
    .tt-button
      margin-top: 30px
      height: 56px
  &__form-text-delivery
    margin-top: 16px
    font-size: 14px
    font-weight: 400
    line-height: 19px

    span
      font-weight: 700
  &__form-text-work
    font-size: 14px
    font-weight: 400
    line-height: 19px

    span
      font-weight: 700
  &__success
    margin-top: 8px
    color: $black
    font-weight: $fw-medium
  &__link
    display: flex
    align-items: center
    padding: 0 10px
</style>
